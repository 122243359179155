import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {InputNumber, Modal} from 'antd';

const CartValueModal = ({visible, loading, submit, minOrderTotal, maxOrderTotal, onClose}) => {
	const [minOrderField, setMinOrderField] = useState(null);
	const [maxOrderField, setMaxOrderField] = useState(null);

	const beforeClose = () => {
		!loading && onClose();
	};

	useEffect(() => {
		if (visible) {
			setMinOrderField(minOrderTotal);
			setMaxOrderField(maxOrderTotal);
		}
	}, [visible]);

	return (
		<Modal
			centered
			open={visible}
			width={344}
			cancelText={'Cancel'}
			okButtonProps={{
				disabled: !minOrderField || !maxOrderField || minOrderField > maxOrderField,
				loading,
			}}
			onOk={() => submit(minOrderField, maxOrderField)}
			onCancel={beforeClose}
		>
			<div className='flex flex-col gap-6'>
				<div className='flex flex-col gap-2'>
					<div>Min. Cart Value (Rp)</div>
					<InputNumber
						disabled={loading}
						className='w-full'
						controls={false}
						value={minOrderField}
						onChange={e => setMinOrderField(e)}
						formatter={value =>
							value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
						}
						parser={value =>
							value?.replace(/\./g, '') || ''
						}
					/>
				</div>
				<div className='flex flex-col gap-2'>
					<div>Max. Cart Value (Rp)</div>
					<InputNumber
						disabled={loading}
						className='w-full'
						controls={false}
						value={maxOrderField}
						onChange={e => setMaxOrderField(e)}
						formatter={value =>
							value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
						}
						parser={value =>
							value?.replace(/\./g, '') || ''
						}
					/>
				</div>
			</div>
		</Modal>
	);
};

CartValueModal.defaultProps = {
	visible: false,
	loading: false,
	submit: () => null,
	minOrderTotal: null,
	maxOrderTotal: null,
	onClose: () => null,
};

CartValueModal.propTypes = {
	visible: PropTypes.bool,
	loading: PropTypes.bool,
	submit: PropTypes.func,
	minOrderTotal: PropTypes.number,
	maxOrderTotal: PropTypes.number,
	onClose: PropTypes.func,
};

export default CartValueModal;