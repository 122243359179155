import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';

import {Badge} from 'antd';
import ProTable from '@ant-design/pro-table';

import {handleErrorFetch, momentDateFormat} from 'utils/utils';
import {getPromoCheck} from 'utils/request/watchtower';

const CHECK_STATUS = [
	{
		text: 'Pending',
		value: 'pending',
	},
	{
		text: 'On Process',
		value: 'processing',
	},
	{
		text: 'Success',
		value: 'done',
	},
	{
		text: 'Failed',
		value: 'failed',
	},
];

const Table = forwardRef(({metricFormRef, setMetricValue}, ref) => {

	const goToDetail = jobId => {
		window.open(`/watchtower/promo/detail/${jobId}`, '_blank');
	};

	const renderStatus = status => {
		let antdStatus = 'default';
		let label = '';
		switch (status) {
		case 'processing':
			antdStatus = 'processing';
			label= 'On Process';
			break;
		case 'done':
			antdStatus = 'success';
			label= 'Success';
			break;
		case 'failed':
			antdStatus = 'error';
			label= 'Failed';
			break;
		case 'pending':
		default:
			antdStatus = 'default';
			label= 'Pending';
		}
		return (
			<Badge
				status={antdStatus}
				text={label}
			/>
		);
	};

	const columns = [
		{
			width: 150,
			key: 'id',
			dataIndex: 'id',
			title: 'Promo Check ID',
		},
		{
			width: 217,
			key: 'createdAt',
			dataIndex: 'createdAt',
			title: 'Time Check',
			sorter: true,
			defaultSortOrder: 'descend',
			render: createdAt => momentDateFormat({dateString: createdAt, format: 'DD MMM YYYY - HH:mm'}),
		},
		{
			key: 'promoOff',
			title: 'Promo Off',
			render: (_, record) => <span>{`${record.offPromoCount} / ${record.totalPromoCount}`}</span>,
		},
		{
			key: 'percentageOff',
			dataIndex: 'percentageOff',
			title: 'Off Percentage',
			render: percentageOff => <span>{`${percentageOff}%`}</span>,
		},
		{
			key: 'checkBy',
			dataIndex: ['creator', 'username'],
			title: 'Check By',
			render: (_, record) => record?.createdBy === null ? 'Robot' : record?.creator?.username,
		},
		{
			key: 'status',
			dataIndex: 'status',
			title: 'Status',
			filters: CHECK_STATUS,
			render: status => renderStatus(status),
		},
		{
			key: 'action',
			title: 'Action',
			fixed: 'right',
			align: 'center',
			render: (_, record) => (
				<span
					onClick={() => goToDetail(record.id)}
					className='text-antd-blue-6 cursor-pointer'>
					Detail
				</span>
			),
		},
	];

	const sortDirectionConverter = direction => {
		switch (direction) {
		case 'ascend':
			return 'asc';
		case 'descend':
			return 'desc';
		default:
			return 'desc';
		}
	};

	const fetchTableData = async (params, sorter, filters) => {
		try {
			/**
			 * Params consist of pagers and table query
			 * Sorter consist of column sort
			 * Filters consist of column filter
			 */
			const metricPayload = metricFormRef.current?.getMetricFilter?.();
			const payload = {
				limit: params?.pageSize,
				offset: (params?.current - 1) * params?.pageSize,
				sortBy: 'createdAt',
				sortOrder: sortDirectionConverter(sorter.createdAt),
				id: metricPayload?.id,
				createdBy: metricPayload?.createdBy,
			};
			if (metricPayload.dateRange?.length) {
				payload.createdAtFrom = metricPayload.dateRange[0].format('YYYY-MM-DD');
				payload.createdAtTo = metricPayload.dateRange[1].format('YYYY-MM-DD');
			}
			if (filters?.status?.length) {
				payload.status = filters?.status.join(',');
			}
			const response = await getPromoCheck(payload);
			metricFormRef.current?.updateMetricData?.({
				totalCheck: response.data.count,
				averageOff: response.data.summary.avgOff || 0,
			});
			if (payload.offset === 0 && payload.sortOrder === 'desc') {
				const latestDoneJob = response.data.rows.find(item => item.status === 'done');
				setMetricValue({
					lastCheckBy: latestDoneJob?.createdBy === null ? 'Robot' : latestDoneJob?.creator?.username,
					lastCheckJobId: latestDoneJob?.id,
					lastCheckTime: momentDateFormat({dateString: latestDoneJob?.createdAt, format: 'DD MMM YYYY - HH:mm'}),
					lastTotalPromoOff: latestDoneJob?.offPromoCount,
				});
			}
			return {
				data: response.data.rows,
				success: response.success,
				total: response.data.count,
			};
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	return (
		<div>
			<ProTable
				actionRef={ref}
				className="mt-4"
				rowKey='external_long_id'
				columns={columns}
				search={false}
				request={fetchTableData}
				toolBarRender={false}
				scroll={{x: 'max-content'}}
				layout="vertical"
				pagination={{
					defaultPageSize: 25,
					showSizeChanger: true,
					showQuickJumper: true,
					pageSizeOptions: ['10', '25', '50', '75', '100'],
				}}
			/>
		</div>
	);
});

Table.defaultProps = {
	metricFormRef: {},
	setMetricValue: () => null,
};

Table.propTypes = {
	metricFormRef: PropTypes.object,
	setMetricValue: PropTypes.func,
};

export default Table;