import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';

// Package
import {Badge, Button, DatePicker, Divider, Modal, notification, Tooltip, Typography} from 'antd';
import {ExclamationCircleOutlined, InfoCircleFilled} from '@ant-design/icons';
import ProTable from '@ant-design/pro-table';
import {debounce, isEmpty} from 'lodash';
import moment from 'moment';
import clsx from 'clsx';

// Components
import MenuTemplateCart from 'components/MenuTemplateCart';

// Utils
import {cancelOrderRequest, deleteOrderRequest, getOrderRequestList, getOrderTemplates, submitOrderRequest} from 'utils/request/internalOrdering';
import {dateFormat, getTimezoneAbbreviation, handleErrorFetch, sortDirectionConverter} from 'utils/utils';
import {INTERNAL_ORDERING_TYPE, KOL_TYPE, OUTLET_TYPE, REFETCH_CAMPAIGN_LIST_TABLE, REQUEST_TABS} from 'utils/constants';
import {getOutletData} from 'utils/request/outlet';

import localization from 'localization';
const locale = localization.InternalOrdering.CampaignDetail.Table;

const INTERNAL_ORDER_STATUS = {
	scheduled: {key: 'scheduled', label: 'Scheduled'},
	processed: {key: 'processed', label: 'Processed'},
	find_driver: {key: 'find_driver', label: 'Finding Driver'},
	driver_found: {key: 'driver_found', label: 'Driver Found'},
	delivery: {key: 'delivery', label: 'Delivering'},
	delivered: {key: 'delivered', label: 'Delivered'},
	cancelled: {key: 'cancelled', label: 'Cancelled'},
	void: {key: 'void', label: 'Void'},
};

const RequestListTable = ({tabKey, refreshDetailAndList, campaignType, campaignId}) => {
	const navigate = useNavigate();

	const tableRef = useRef(null);
	const menuTemplateCartRef = useRef(null);

	const [cart, setCart] = useState([]);
	const [previewData, setPreviewData] = useState({});
	const [loadingOrderTemplate, setLoadingOrderTemplate] = useState(false);
	const [loadingDelete, setLoadingDelete] = useState(false);
	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [locationList, setLocationList] = useState([]);
	const [locationLoading, setLocationLoading] = useState(false);

	const [selectedRows, setSelectedRows] = useState([]);

	const isKOL = campaignType == INTERNAL_ORDERING_TYPE.kol.key;
	const isBulk = campaignType == INTERNAL_ORDERING_TYPE.bulk.key;

	const renderStatusBadge = record => {
		let statusColor = 'default';
		const statusText = Object?.values
			?.(INTERNAL_ORDER_STATUS)
			?.find
			?.(orderStatus => orderStatus?.key == record?.status)
			?.label || '-';

		switch (record.status) {
		case INTERNAL_ORDER_STATUS.scheduled.key:
			statusColor = 'default';
			break;
		case INTERNAL_ORDER_STATUS.processed.key:
		case INTERNAL_ORDER_STATUS.find_driver.key:
		case INTERNAL_ORDER_STATUS.driver_found.key:
			statusColor = 'processing';
			break;
		case INTERNAL_ORDER_STATUS.delivery.key:
			statusColor = 'warning';
			break;
		case INTERNAL_ORDER_STATUS.delivered.key:
			statusColor = 'success';
			break;
		case INTERNAL_ORDER_STATUS.cancelled.key:
		case INTERNAL_ORDER_STATUS.void.key:
			statusColor = 'error';
			break;
		default:
			break;
		}

		return (
			<Badge
				status={statusColor}
				text={statusText}
			/>
		);
	};

	const openMenuCombination = async orderTemplateId => {
		try {
			setLoadingOrderTemplate(true);
			const response = await getOrderTemplates({search: {id: orderTemplateId}});
			if (response.success && response?.data?.rows?.length) {
				const itemsData = response?.data?.rows[0]?.items || [];
				const tempCart = itemsData.map(item => {
					return {
						dataOrigin: {},
						menuItem: item,
						mappingHash: item.mappingHash,
					};
				});
				setCart(tempCart);
				setPreviewData(response?.data?.rows[0]);
				menuTemplateCartRef?.current?.openDrawer?.();
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoadingOrderTemplate(false);
		}
	};

	const goToEditOrderRequest = record => {
		navigate(`/internal-ordering/order-creator/campaign-detail/${record.internalOrderCampaignId}/${record.id}/edit`, {
			state: {
				orderType: record.orderType,
				campaignType: record.InternalOrderCampaign.type,
				requestData: record,
			},
		});
	};

	const goToDetailOrderRequest = record => {
		navigate(`/internal-ordering/order-creator/campaign-detail/${record.internalOrderCampaignId}/${record.id}/detail`, {
			state: {
				orderType: record.orderType,
				campaignType: record.InternalOrderCampaign.type,
				requestData: record,
			},
		});
	};

	const removeRequestConfirmation = (requestId, type = 'delete') => {
		const isDelete = type === 'delete';
		const actionType = isDelete ? 'Delete' : 'Cancel';
		const callFn = isDelete ? deleteOrderRequest : cancelOrderRequest;

		Modal.confirm({
			title: locale[`${actionType}Confirmation`].title,
			content: locale[`${actionType}Confirmation`].content.replace('{{requestId}}', requestId?.toUpperCase?.()),
			cancelText: locale[`${actionType}Confirmation`].cancelText,
			okText: locale[`${actionType}Confirmation`].okText,
			icon: <ExclamationCircleOutlined />,
			centered: true,
			maskClosable: true,
			okButtonProps: {danger: true, loading: loadingDelete},
			onOk: async () => {
				try {
					setLoadingDelete(true);
					const response = await callFn({id: requestId});
					if (response.success) {
						refreshDetailAndList();
						setSelectedRows(state => state.filter(item => item !== requestId));
						notification.success({
							message: locale[`${actionType}SuccessNotification`].message,
							description: locale[`${actionType}SuccessNotification`].description,
						});
					}
					return;
				} catch (error) {
					handleErrorFetch(error, {
						message: locale[`${actionType}FailedNotification`].message,
						description: locale[`${actionType}FailedNotification`].description,
					});
				} finally {
					setLoadingDelete(false);
				}
			},
		});
	};

	const submitCofirmation = requestId => {
		Modal.confirm({
			title: locale.SubmitConfirmation.title,
			content: locale.SubmitConfirmation.content.replace('{{requestId}}', requestId?.toUpperCase?.()),
			cancelText: locale.SubmitConfirmation.cancelText,
			okText: locale.SubmitConfirmation.okText,
			centered: true,
			maskClosable: true,
			okButtonProps: {loading: loadingSubmit},
			onOk: async () => {
				try {
					setLoadingSubmit(true);
					const response = await submitOrderRequest({id: requestId});
					if (response.success) {
						setSelectedRows(state => state.filter(item => item !== requestId));
						refreshDetailAndList();
						notification.success({
							message: locale.SubmitSuccessNotification.message,
							description: locale.SubmitSuccessNotification.description,
						});
					}
				} catch (error) {
					handleErrorFetch(error, {
						message: locale.SubmitFailedNotification.message,
						description: locale.SubmitFailedNotification.description,
					});
				} finally {
					setLoadingSubmit(false);
				}
			},
		});
	};

	const bulkDeleteConfirmation = () => {
		Modal.confirm({
			title: locale.DeleteConfirmation.title,
			content: locale.DeleteConfirmation.bulkContent.replace('{{count}}', selectedRows.length),
			cancelText: locale.DeleteConfirmation.cancelText,
			okText: locale.DeleteConfirmation.okText,
			icon: <ExclamationCircleOutlined />,
			centered: true,
			maskClosable: true,
			okButtonProps: {danger: true, loading: loadingDelete},
			onOk: async () => {
				try {
					setLoadingDelete(true);

					const deletePromises = selectedRows.map(requestId =>
						deleteOrderRequest({id: requestId}),
					);

					const responses = await Promise.all(deletePromises);

					const successfulResponses = responses.filter(response => response.success);
					setSelectedRows([]);

					if (successfulResponses.length > 0) {
						refreshDetailAndList();
						notification.success({
							message: locale.DeleteSuccessNotification.message,
							description: locale.DeleteSuccessNotification.description,
						});
					} else {
						notification.error({
							message: locale.DeleteFailedNotification.message,
							description: locale.DeleteFailedNotification.description,
						});
					}
				} catch (error) {
					handleErrorFetch(error, {
						message: locale.DeleteFailedNotification.message,
						description: locale.DeleteFailedNotification.description,
					});
				} finally {
					setLoadingDelete(false);
				}
			},
		});
	};

	const bulkSubmitConfirmation = () => {
		Modal.confirm({
			title: locale.SubmitConfirmation.title,
			content: locale.SubmitConfirmation.bulkContent.replace('{{count}}', selectedRows.length),
			cancelText: locale.SubmitConfirmation.cancelText,
			okText: locale.SubmitConfirmation.okText,
			centered: true,
			maskClosable: true,
			okButtonProps: {loading: loadingSubmit},
			onOk: async () => {
				try {
					setLoadingSubmit(true);

					const submitPromises = selectedRows.map(requestId =>
						submitOrderRequest({id: requestId}),
					);

					const responses = await Promise.all(submitPromises);

					const successfulResponses = responses.filter(response => response.success);
					setSelectedRows([]);

					if (successfulResponses.length > 0) {
						refreshDetailAndList();
						notification.success({
							message: locale.SubmitSuccessNotification.message,
							description: locale.SubmitSuccessNotification.description,
						});
					} else {
						notification.error({
							message: locale.SubmitFailedNotification.message,
							description: locale.SubmitFailedNotification.description,
						});
					}
				} catch (error) {
					handleErrorFetch(error, {
						message: locale.SubmitFailedNotification.message,
						description: locale.SubmitFailedNotification.description,
					});
				} finally {
					setLoadingSubmit(false);
				}
			},
		});
	};

	const isWithinTimeWindow = timestamp => {
		const scheduledTime = moment(timestamp);
		const timeCutoff = moment().add(2, 'hours');

		return timeCutoff.isSameOrAfter(scheduledTime);
	};

	const getLocation = async outletLabel => {
		try {
			if (outletLabel.length === 0 && locationList.length !== 0) {
				return;
			}
			setLocationLoading(true);
			const response = await getOutletData({
				search: {label: outletLabel, limit: 0},
			}, OUTLET_TYPE.LOCATION);
			if (response.success) {
				setLocationList(response.data.rows);
			} else {
				throw {};
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLocationLoading(false);
		}
	};

	const debounceFn = useCallback(debounce(getLocation, 500), []);

	const scheduleTimeExpired = timestamp => {
		const inputTime = new Date(timestamp);
		const currentTime = new Date();
		return inputTime < currentTime;
	};

	const getColumnAction = record => {
		const output = [];

		const addDivider = () => {
			output.push(<Divider
				className='m-0'
				type='vertical' />);
		};

		if (tabKey == REQUEST_TABS.DRAFT) {
			output.push(
				<a
					className='text-antd-blue-6'
					onClick={() => goToEditOrderRequest(record)}
				>{locale.edit}</a>,
			);
			addDivider();

			console.log(record);

			if (scheduleTimeExpired(record?.scheduleTime)) {
				output.push(
					<Tooltip title={locale.expiredSubmit}>
						<a
							className='text-antd-netural-5 cursor-not-allowed'
						>{locale.submit}</a>
					</Tooltip>,
				);
			} else {
				output.push(
					<a
						onClick={() => submitCofirmation(record.id)}
						className='text-antd-blue-6'
					>{locale.submit}</a>,
				);
			}

			addDivider();
			output.push(
				<a
					onClick={() => removeRequestConfirmation(record.id, 'delete')}
					className='text-antd-red-6'
				>{locale.delete}</a>,
			);
		}

		if (tabKey == REQUEST_TABS.ACTIVE || tabKey == REQUEST_TABS.DONE) {
			output.push(
				<a
					className='text-antd-blue-6'
					onClick={() => goToDetailOrderRequest(record)}
				>{locale.detail}</a>,
			);

			const trackingLink = get(record, ['PosOrder', 'Deliveries', 0, 'details', 'deliveryUrl']);

			if (trackingLink) {
				addDivider();
				output.push(
					<a
						onClick={() => window.open(trackingLink, '_blank')}
						className='text-antd-blue-6'
					>{locale.tracking}</a>,
				);
			}

			if (tabKey == REQUEST_TABS.ACTIVE) {

				// If schedule time is within 2 hours from now, request cant be cancel
				if (!isWithinTimeWindow(record?.scheduleTime)) {

					if ([
						INTERNAL_ORDER_STATUS.scheduled.key,
					].some(item => item == record.status)) {
						addDivider();
						output.push(
							<a
								onClick={() => removeRequestConfirmation(record.id, 'cancel')}
								className='text-antd-red-6'
							>{locale.cancel}</a>,
						);
					}
				}
			}
		}

		return output;
	};

	const columns = [
		{
			title: locale.requestId,
			width: 150,
			key: 'id',
			dataIndex: 'id',
			render: id => <Typography.Text copyable>{id?.toUpperCase?.()}</Typography.Text>,
		},
		...(tabKey == REQUEST_TABS.DRAFT ? [] : [		{
			title: locale.status,
			width: 168,
			key: 'status',
			dataIndex: 'status',
			render: (_, record) => renderStatusBadge(record),
			search: false,
			filterMultiple: false,
			filters: Object.values(INTERNAL_ORDER_STATUS).map(internalOrderStatus => {
				return {
					text: internalOrderStatus.label,
					value: internalOrderStatus.key,
				};
			}),
		}]),
		{
			title: locale.orderType,
			width: 153,
			key: 'orderType',
			dataIndex: 'orderType',
			render: orderType => <span className='capitalize'>{orderType}</span>,
			search: false,
			filterMultiple: false,
			filters: [
				{
					text: 'Delivery',
					value: 'delivery',
				},
				{
					text: 'Takeaway',
					value: 'takeaway',
				},
			],
		},
		...(isKOL ? [{
			title: locale.kolType,
			width: 168,
			key: 'kolType',
			dataIndex: 'kolType',
			render: kolType => KOL_TYPE?.[kolType],
			search: false,
			filterMultiple: false,
			filters: Object.keys(KOL_TYPE).map(kolType => {
				return {
					text: KOL_TYPE[kolType],
					value: kolType,
				};
			}),
		}] : []),
		...(tabKey == REQUEST_TABS.DRAFT ? [] : [{
			title: locale.billNumber,
			width: 163,
			key: 'billNumber',
			dataIndex: ['PosOrder', 'billNumber'],
			search: false,
			render: (_, record) => {
				const idExist = record?.PosOrder?.id;
				return (
					<span
						className={idExist ? 'cursor-pointer text-antd-blue-6' : ''}
						onClick={() => idExist && window.open(`/watchtower/order/pos/${idExist}`, '_blank')}
					>{record?.PosOrder?.billNumber || '-'}</span>
				);
			},
		}]),
		{
			title: locale.customerName,
			width: 141,
			key: 'customerName',
			dataIndex: 'customerName',
		},
		{
			title: locale.scheduledAt,
			width: 187,
			key: 'scheduleTime',
			sorter: true,
			dataIndex: 'scheduleTime',
			renderFormItem: () => (
				<DatePicker.RangePicker
					format={'DD MMM YYYY'}
					ranges={{
						Today: [moment(), moment()],
						'This Month': [moment().startOf('month'), moment().endOf('month')],
					}}
					placeholder={['From', 'Until']}
				/>
			),
			render: scheduleTime => scheduleTime ? `${moment(scheduleTime).format('DD MMM YYYY - HH:mm')} ${getTimezoneAbbreviation(moment(scheduleTime).format('Z'))}` : '-',
		},
		{
			title: locale.menuCombinationId,
			width: 289,
			key: 'menuSearch',
			dataIndex: 'menuSearch',
			render: (_, record) => (
				<span
					className={clsx(
						'capitalize flex gap-2 text-antd-blue-6 cursor-pointer',
					)}
					onClick={() => !loadingOrderTemplate && openMenuCombination(record?.orderTemplateId)}
				>
					{`${record?.orderTemplateId?.toUpperCase?.()} · ${record?.OrderTemplate?.name}`}
				</span>
			),
		},
		{
			title: locale.outlet,
			width: 207,
			key: 'locationId',
			dataIndex: ['Location', 'label'],
			valueType: 'select',
			fieldProps: {
				onSearch: debounceFn,
				loading: locationLoading,
				showSearch: true,
				options: locationList.map(location => {
					return {
						label: location.label,
						value: location.id,
					};
				}),
			},
		},
		...(isBulk ? [
			{
				title: locale.discountPercentage,
				width: 180,
				key: 'discountPercentage',
				dataIndex: 'discountPercentage',
				render: discountPercentage => <span>{discountPercentage}%</span>,
			},
		] : []),
		...(tabKey == REQUEST_TABS.DRAFT
			? [{
				title: locale.createdBy,
				width: 136,
				key: 'createdBy',
				dataIndex: ['creator', 'username'],
			},
			{
				title: locale.createdAt,
				width: 195,
				key: 'createdAt',
				dataIndex: 'createdAt',
				sorter: true,
				defaultSortOrder: 'descend',
				search: false,
				render: createdAt => dateFormat(createdAt),
			},
			{
				title: locale.editedBy,
				width: 155,
				key: 'updatedBy',
				search: false,
				dataIndex: ['updater', 'username'],
			},
			{
				title: locale.editedAt,
				width: 195,
				key: 'updatedAt',
				sorter: true,
				dataIndex: 'updatedAt',
				search: false,
				render: updatedAt => updatedAt ? dateFormat(updatedAt) : '-',
			}]
			: [{
				title: locale.submittedBy,
				width: 155,
				key: 'submittedBy',
				dataIndex: ['submitter', 'username'],
			},
			{
				title: locale.submittedAt,
				width: 195,
				key: 'submittedAt',
				sorter: true,
				dataIndex: 'submittedAt',
				search: false,
				render: submittedAt => submittedAt ? dateFormat(submittedAt) : '-',
			}]
		),
		...(tabKey == REQUEST_TABS.DONE ? [{
			title: locale.cancelledBy,
			width: 155,
			key: 'cancelledBy',
			search: false,
			dataIndex: ['canceller', 'username'],
		},
		{
			title: locale.cancelledAt,
			width: 195,
			key: 'cancelledAt',
			sorter: true,
			search: false,
			dataIndex: 'cancelledAt',
			render: cancelledAt => cancelledAt == '-' ? '-' : dateFormat(cancelledAt),
		}] : []),
		{
			title: locale.action,
			width: 197,
			key: 'action',
			align: 'center',
			search: false,
			fixed: 'right',
			render: (_, record) => (
				<div className='flex gap-2 justify-center items-center'>
					{getColumnAction(record)}
				</div>
			),
		},
	];

	const fetchTableData = async (params, sorter, filters) => {
		try {
			/**
			 * Params consist of pagers and table query
			 * Sorter consist of column sort
			 * Filters consist of column filter
			 */

			const sortOrderKey = isEmpty(sorter) ? 'descend' : Object.values?.(sorter)?.[0];
			const sortByKey = isEmpty(sorter) ? 'createdAt' : Object.keys?.(sorter)?.[0];

			const payload = {
				internalOrderCampaignId: campaignId,
				requestStatus: tabKey,
				limit: params?.pageSize,
				page: params?.current - 1,
				sortBy: sortByKey,
				sortOrder: sortDirectionConverter(sortOrderKey),
				search: {
					id: params?.id?.toLowerCase?.() || null,
					customerName: params?.customerName || null,
					locationId: params?.locationId || null,
					discountPercentage: params?.discountPercentage || null,
					submittedBy: params?.submittedBy || null,
					menuSearch: params?.menuSearch?.toLowerCase?.() || null,
					orderType: filters?.orderType?.[0] || null,
					createdBy: params?.createdBy || null,
					type: filters?.type?.[0] || null,
					status: filters?.status?.[0] || null,
					kolType: filters?.kolType?.[0] || null,
					scheduleTimeFrom: params?.scheduleTime?.[0] ? moment(params?.scheduleTime?.[0], 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD') : null,
					scheduleTimeTo: params?.scheduleTime?.[1] ? moment(params?.scheduleTime?.[1], 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD') : null,
				},
			};
			const response = await getOrderRequestList(payload);
			return {
				data: response.data.rows,
				success: response.success,
				total: response.data.count,
			};
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	const listenRefetch = event => {
		if (event.data === REFETCH_CAMPAIGN_LIST_TABLE) {
			tableRef?.current?.reload?.();
		}
	};

	const renderTableAlert = ({selectedRowKeys}) =>
		selectedRowKeys.length > 0 && (
			<div className='flex gap-3 items-center'>
				<InfoCircleFilled className='text-antd-blue-6' />
				<div>
					<span className='font-semibold'>{selectedRowKeys.length}</span> items selected
				</div>
			</div>
		);

	const renderTableAlertOption = () => (
		<div className='flex gap-2 items-center'>
			<div
				onClick={() => {
					setSelectedRows([]);
				}}
				className='text-antd-blue-6 px-2 cursor-pointer'>
				{locale.clear}
			</div>
			<Button
				danger
				size='small'
				type="primary"
				onClick={bulkDeleteConfirmation}
				disabled={selectedRows.length === 0}
			>
				{`${locale.delete} (${selectedRows.length})`}
			</Button>
			<Button
				size='small'
				type="primary"
				onClick={bulkSubmitConfirmation}
				disabled={selectedRows.length === 0}
			>
				{`${locale.submit} (${selectedRows.length})`}
			</Button>
		</div>
	);

	const handleOnSelect = (record, selected) => {
		if (selected) { // Check
			setSelectedRows(state => state.concat(record.id));
		} else { // Uncheck
			setSelectedRows(state => state.filter(item => item !== record.id));
		}
	};

	const handleSelectAll = (selected, _, changeRows) => {
		if (selected) {
			const tempSelectedRows = [];

			changeRows.forEach(item => {
				tempSelectedRows.push(item.id);
			});

			setSelectedRows(state => state.concat(tempSelectedRows));
		} else {
			const idOfChangeRows = changeRows.map(item => item.id);
			setSelectedRows(state => state.filter(item => !idOfChangeRows.includes(item)));
		}
	};

	const rowSelection = tabKey == REQUEST_TABS.DRAFT ? {
		selectedRowKeys: selectedRows,
		onSelectAll: handleSelectAll,
		onSelect: handleOnSelect,
		getCheckboxProps: record => ({
			disabled: scheduleTimeExpired(record?.scheduleTime),
		}),
	} : false;

	useEffect(() => {
		window.addEventListener('message', listenRefetch, false);
		return () => {
			window.removeEventListener('message', listenRefetch);
		};
	}, []);

	return (
		<>
			<MenuTemplateCart
				ref={menuTemplateCartRef}
				viewOnly
				title={`${previewData?.id?.toUpperCase?.()} ∙ ${previewData?.name}`}
				cart={cart}
				hideButton
			/>
			<div>
				<ProTable
					loading={loadingOrderTemplate || loadingDelete || loadingSubmit}
					actionRef={tableRef}
					className="mt-4 ResetSearchTableMargin"
					rowKey='id'
					columns={columns}
					request={fetchTableData}
					rowSelection={rowSelection}
					toolBarRender={false}
					tableAlertRender={renderTableAlert}
					tableAlertOptionRender={renderTableAlertOption}
					scroll={{x: 'max-content'}}
					onReset={() => tableRef?.current?.reset()}
					search={{
						layout: 'vertical',
					}}
					pagination={{
						defaultPageSize: 25,
						showSizeChanger: true,
						showQuickJumper: true,
						pageSizeOptions: ['10', '25', '50', '75', '100'],
					}}
				/>
			</div>
		</>
	);
};

RequestListTable.defaultProps = {
	tabKey: null,
	refreshDetailAndList: () => null,
	campaignType: null,
	campaignId: null,
};

RequestListTable.propTypes = {
	tabKey: PropTypes.string,
	refreshDetailAndList: PropTypes.func,
	campaignType: PropTypes.string,
	campaignId: PropTypes.number,
};

export default RequestListTable;