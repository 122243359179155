import {MASTERLIST_TYPE, USER_ROLES} from 'utils/constants';
import {dateFormat, getLabel, moneyFormat, getStationLabel, ellipsisItem} from 'utils/utils';

import {Popconfirm, Select, Tag, Typography} from 'antd';
import ProtectedComponent from 'components/ProtectedComponent';
const {Paragraph} = Typography;

const DEFAULT_TABLE_COLUMN = ['createdBy', 'createdAt', 'updatedBy', 'updatedAt', 'action'];
const TABLE_CONTENT = {
	[MASTERLIST_TYPE.CATEGORY]: ['label', 'posName', 'id', 'tags', 'isActive', ...DEFAULT_TABLE_COLUMN],
	[MASTERLIST_TYPE.MENU]: ['menuLabel', 'kdsName', 'menuCode', 'id', 'externalId', 'station', 'tags', 'price', 'isActive', 'isFreeItem', ...DEFAULT_TABLE_COLUMN],
	[MASTERLIST_TYPE.CHILD_MENU]: ['menuLabel', 'kdsName', 'menuCode', 'id', 'externalId', 'station', 'tags', 'price', 'isActive', ...DEFAULT_TABLE_COLUMN],
	[MASTERLIST_TYPE.OPTION_GROUP]: ['label', 'id', 'tags', 'isActive', ...DEFAULT_TABLE_COLUMN],
	[MASTERLIST_TYPE.MATERIAL]: ['materialLabel', 'materialCode', ...DEFAULT_TABLE_COLUMN],
	[MASTERLIST_TYPE.REMINDER]: ['name', 'id', 'station', 'tags', 'isActive', ...DEFAULT_TABLE_COLUMN],
};

export default (config = {}) => {
	const {
		sortTableHeader = () => null,
		masterlistType,
		goToEditPage = () => null,
		openDetailModal = () => null,
		deleteItem = () => null,
	} = config;

	const columnTemplate = [
		{
			title: null,
			dataIndex: 'label',
			key: 'label',
			fixed: 'left',
			ellipsis: true,
		},
		{
			title: 'Menu Reminder Name',
			dataIndex: 'name',
			key: 'name',
			fixed: 'left',
			ellipsis: true,
		},
		{
			title: null,
			dataIndex: 'menuLabel',
			key: 'menuLabel',
			fixed: 'left',
			width: 250,
		},
		{
			title: 'Material Label',
			dataIndex: 'materialLabel',
			key: 'materialLabel',
			fixed: 'left',
			ellipsis: true,
		},
		{
			title: 'POS Category Name',
			dataIndex: 'posName',
			key: 'posName',
			width: 208,
		},
		{
			title: 'KDS Name',
			dataIndex: 'kdsName',
			key: 'kdsName',
		},
		{
			title: 'Menu Code',
			dataIndex: 'menuCode',
			key: 'menuCode',
		},
		{
			title: 'Material Code',
			dataIndex: 'materialCode',
			key: 'materialCode',
		},
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			render: id => id === '-' ? <span>-</span> : <Paragraph copyable>{id}</Paragraph>,
		},
		{
			title: 'External ID',
			dataIndex: 'externalId',
			key: 'externalId',
			// render: externalId => externalId === '-' ? <span>-</span> : <Paragraph copyable>{externalId}</Paragraph>,
			render: externalId => externalId === '-' ? <span>-</span> : ellipsisItem(externalId),
			width: 200,
		},
		{
			title: 'Station',
			dataIndex: 'station',
			key: 'station',
			render: station => getStationLabel(station),
		},
		{
			title: 'Tags',
			dataIndex: 'tags',
			key: 'tags',
			width: 208,
			renderFormItem: () => (
				<Select
					open={false}
					mode='tags'
					tokenSeparators={[',']}
				/>
			),
			render: tags => (Array.isArray(tags) && tags.length) ? (
				<div className='flex flex-wrap'>
					{tags.map((tag, index) => (
						<Typography.Text
							key={index}
							style={{maxWidth: 180}}
							ellipsis={{tooltip: tag}}
						>
							<Tag
								style={{maxWidth: 180}}
								color='blue'
								className='uppercase truncate'
								key={index}>{tag}
							</Tag>
						</Typography.Text>
					))}
				</div>
			) : '-',
		},
		{
			title: () => sortTableHeader('Price', 'price'),
			dataIndex: 'price',
			key: 'price',
			search: false,
			width: 150,
			render: price => (
				<div>{moneyFormat({value: price})}</div>
			),
		},
		{
			title: 'Active',
			dataIndex: 'isActive',
			key: 'isActive',
			width: 100,
			valueEnum: {
				all: {text: 'All', status: 'Default'},
				active: {
					text: 'Yes',
					status: true,
				},
				inactive: {
					text: 'No',
					status: false,
				},
			},
			render: (_, record) => (
				<div className={
					`font-semibold ${record.isActive ? 'text-green-400' : 'text-red-400'}`
				}>{record.isActive ? 'Yes' : 'No'}</div>
			),
		},
		{
			title: 'Free Item',
			dataIndex: 'isFreeItem',
			key: 'isFreeItem',
			width: 100,
			valueEnum: {
				all: {text: 'All', status: 'Default'},
				active: {
					text: 'Yes',
					status: true,
				},
				inactive: {
					text: 'No',
					status: false,
				},
			},
			render: (_, record) => (
				<div className={
					`font-semibold ${record.isFreeItem ? 'text-green-400' : 'text-red-400'}`
				}>{record.isFreeItem ? 'Yes' : 'No'}</div>
			),
		},
		{
			title: 'Created By',
			dataIndex: ['creator', 'username'],
			key: 'createdBy',
		},
		{
			title: () => sortTableHeader('Created At', 'createdAt'),
			dataIndex: 'createdAt',
			key: 'createdAt',
			search: false,
			render: createdAt => (
				<div>{dateFormat(createdAt)}</div>
			),
		},
		{
			title: 'Updated By',
			dataIndex: ['updater', 'username'],
			key: 'updatedBy',
		},
		{
			title: () => sortTableHeader('Updated At', 'updatedAt'),
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			search: false,
			render: updatedAt => (
				<div>{dateFormat(updatedAt)}</div>
			),
		},
		{
			title: 'Action',
			align: 'center',
			key: 'action',
			fixed: 'right',
			search: false,
			width: masterlistType === MASTERLIST_TYPE.MATERIAL ? 169 : 120,
			render: (_, record, index) => (
				<div className="text-center text-blue-400 flex gap-2 items-center justify-center">
					<ProtectedComponent when={u => u.roles.some(role => [USER_ROLES.MASTERLIST.ADMIN, USER_ROLES.MASTERLIST.EDITOR].includes(role))}>
						<span
							onClick={() => goToEditPage(record)}
							className='cursor-pointer'
						>
							Edit
						</span>
					</ProtectedComponent>
					{
						[MASTERLIST_TYPE.MENU, MASTERLIST_TYPE.MATERIAL].includes(masterlistType) && (
							<span
								onClick={() => openDetailModal(index)}
								className='cursor-pointer'
							>
								Detail
							</span>
						)
					}
					{
						[MASTERLIST_TYPE.MATERIAL].includes(masterlistType) && (
							<Popconfirm
								placement='topRight'
								title='Are you sure you want to delete this material?'
								onConfirm={() => deleteItem(record)}
							>
								<span className='cursor-pointer'>
									Delete
								</span>
							</Popconfirm>
						)
					}
				</div>
			),
		},
	];

	let output = [];

	columnTemplate.forEach(column => {
		// If column in included table content, push column to output*
		if (TABLE_CONTENT[masterlistType].some(content => content === column.key)) {

			// Before push check if its label or not, if its label, column title need to be specified
			if (['menuLabel', 'label'].some(item => item === column.key)) {
				output.push({
					...column,
					title: `${getLabel(masterlistType, ' ', true)} Name`,
				});
			} else {
				output.push(column);
			}
		}
	});

	return output;
};