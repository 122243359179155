import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';

import {EMAIL_PROVIDER, OUTLET_TYPE} from 'utils/constants';
import {getEmailFront, handleErrorFetch} from 'utils/utils';
import {createNewOutletItem, editOutletItem, getTimezone} from 'utils/request/outlet';

import {Button, Card, Form, Input, InputNumber, notification, Radio, Select} from 'antd';

import localization from 'localization';
const locale = localization.Outlet.LocationForm.Form;

// const timeZoneConfig = [
// 	{label: 'WIB'},
// 	{label: 'WITA'},
// 	{label: 'WIT'},
// ];

const OutletLocationFormBody = ({isEdit, itemRecord}) => {
	const navigate = useNavigate();
	const [form] = Form.useForm();

	const [isLoading, setIsLoading] = useState(false);
	const [timezones, setTimezones] = useState([]);

	const handleSubmit = async e => {
		try {
			setIsLoading(true);
			if (e.isActive === 1) {
				e.isActive = true;
			} else {
				e.isActive = false;
			}
			const fetchFn = isEdit ? editOutletItem : createNewOutletItem;

			// Move branchID to details
			e.details = {branchID: e.branchID};
			delete e.branchID;

			e.ordersThreshold = {
				min: e.minQueue,
				max: e.maxQueue,
				minOrderTotal: e.minOrderTotal,
				maxOrderTotal: e.maxOrderTotal,
			};
			delete e.minQueue;
			delete e.maxQueue;
			delete e.minOrderTotal;
			delete e.maxOrderTotal;

			e.timezone = timezones.find(timezone => timezone.label === e.timezone);
			e.email = `${e.email}${EMAIL_PROVIDER}`;

			if (isEdit) e.locationId = itemRecord.id;
			const response = await fetchFn(e, OUTLET_TYPE.LOCATION);
			if (response.success) {
				const notificationLocale = isEdit ? locale.action.EditSuccess : locale.action.CreateSuccess;
				navigate('/outlet/location');
				notification.open({
					message: notificationLocale.title,
					description: notificationLocale.description,
					type: 'success',
				});
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setIsLoading(false);
		}
	};

	const initalFetch = async () => {
		try {
			setIsLoading(true);
			const timezoneResponse = await getTimezone();
			if (timezoneResponse.success) {
				setTimezones(timezoneResponse.data);
			} else throw {};
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		initalFetch();
		if (isEdit) {
			const branchInfo = itemRecord?.Branch;
			form.setFieldsValue({
				isActive: itemRecord.isActive ? 1 : 0,
				label: itemRecord.label,
				region: itemRecord.region,
				tags: itemRecord.tags,
				shortAddress: itemRecord.shortAddress,
				posId: branchInfo?.posId || 1, // ESB Default ID
				branchID: branchInfo?.details?.branchID || null,
				timezone: itemRecord?.timezone?.label,
				maxQueue: itemRecord?.maxQueue,
				minOrderTotal: itemRecord?.minOrderTotal,
				maxOrderTotal: itemRecord?.maxOrderTotal,
				minQueue: itemRecord?.minQueue,
				latitude: itemRecord?.latitude,
				longitude: itemRecord?.longitude,
				email: getEmailFront(itemRecord?.email),
			});
		}
	}, []);

	return (
		<div className='px-6 pb-6'>
			<Card
				title={isEdit ? locale.editTitle : locale.defaultTitle}
			>
				<div>
					<Form
						form={form}
						layout="vertical"
						name="locationForm"
						scrollToFirstError
						onFinish={e => !isLoading && handleSubmit(e)}
						onKeyDown={e => e.code === 'Enter' && e.preventDefault()}
						requiredMark={false}
						initialValues={{
							isActive: 1,
							posId: 1,
						}}
					>
						<Form.Item
							label={locale.locationNameLabel}
							name="label"
							rules={[{required: true, message: locale.locationNameRequired}]}
						>
							<Input
								className='w-96'
								placeholder={locale.locationNamePlaceholder}
							/>
						</Form.Item>
						<Form.Item
							label={locale.regionLabel}
							name="region"
							rules={[{required: true, message: locale.regionRequired}]}
						>
							<Input
								className='w-96'
								placeholder={locale.regionPlaceholder}
							/>
						</Form.Item>
						<Form.Item
							label={locale.shortAddressLabel}
							name="shortAddress"
							rules={[{required: true, message: locale.shortAddressRequired}]}
						>
							<Input.TextArea
								showCount
								maxLength={80}
								className='w-96'
								placeholder={locale.shortAddressPlaceholder}
							/>
						</Form.Item>
						<Form.Item
							label={locale.emailLabel}
							name="email"
							rules={[
								{required: true, message: locale.emailRequired},
								{validator: (_, value) => {
									const regex = /^[a-zA-Z0-9._%+-]+$/;

									if (!value || regex.test(value)) {
										return Promise.resolve();
									}

									return Promise.reject(new Error('Invalid characters for the email user'));
								}},
							]}
						>
							<Input
								addonAfter={EMAIL_PROVIDER}
								className='w-96'
								placeholder={locale.emailPlaceholder}
							/>
						</Form.Item>
						<Form.Item
							label={locale.latLabel}
							name="latitude"
							rules={[{required: true, message: locale.latRequired}]}
						>
							<Input
								className='w-96'
								placeholder={locale.latPlaceholder}
							/>
						</Form.Item>
						<Form.Item
							label={locale.longLabel}
							name="longitude"
							rules={[{required: true, message: locale.longRequired}]}
						>
							<Input
								className='w-96'
								placeholder={locale.longPlaceholder}
							/>
						</Form.Item>
						<Form.Item
							label={locale.tagsLabel}
							name="tags">
							<Select
								placeholder={locale.tagsPlaceholder}
								className='w-96'
								open={false}
								mode='tags'
								tokenSeparators={[',']}
							/>
						</Form.Item>
						{/* POS */}
						{/* <Form.Item
							label={locale.posLabel}
							name="posId"
						>
							<Select
								className='w-96'
								disabled>
								<Select.Option
									key='esb'
									value={1}>ESB</Select.Option>
							</Select>
						</Form.Item> */}

						{/* Branch ID */}
						<Form.Item
							label={locale.branchIdLabel}
							name="branchID"
							rules={[{required: true, message: locale.branchIdRequired}]}
						>
							<Input
								className='w-96'
								placeholder={locale.branchIdPlaceholder}
							/>
						</Form.Item>

						{/* Min Queue */}
						<Form.Item
							label={locale.minCartValueLabel}
							name="minOrderTotal"
							rules={[{required: true, message: locale.minCartValueRequired}]}
						>
							<InputNumber
								min={0}
								controls={false}
								className='w-96'
								placeholder={locale.minCartValuePlaceholder}
								formatter={value =>
									value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
								}
								parser={value =>
									value?.replace(/\./g, '') || ''
								}
							/>
						</Form.Item>

						{/* Max Queue */}
						<Form.Item
							label={locale.maxCartValueLabel}
							name="maxOrderTotal"
							rules={[{required: true, message: locale.maxCartValueRequired}]}
						>
							<InputNumber
								min={0}
								controls={false}
								className='w-96'
								placeholder={locale.maxCartValuePlaceholder}
								formatter={value =>
									value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
								}
								parser={value =>
									value?.replace(/\./g, '') || ''
								}
							/>
						</Form.Item>

						{/* Timezone */}
						<Form.Item
							label={locale.timeZoneLabel}
							name="timezone"
							rules={[{required: true, message: locale.timeZoneRequired}]}
						>
							<Radio.Group>
								{timezones.map(timezone => (
									<Radio
										key={timezone.label}
										value={timezone.label}>{timezone.label}</Radio>
								))}
							</Radio.Group>
						</Form.Item>

						{/* Active */}
						<Form.Item
							label={locale.statusLabel}
							name="isActive">
							<Radio.Group>
								<Radio
									key='active'
									value={1}>Yes</Radio>
								<Radio
									key='inactive'
									value={0}>No</Radio>
							</Radio.Group>
						</Form.Item>
						<Form.Item>
							<div className='flex gap-2 justify-end'>
								<Button
									onClick={() => navigate('/outlet/location')}
									disabled={isLoading}>
									{locale.cancel}
								</Button>
								<Button
									type="primary"
									htmlType="submit"
									loading={isLoading}>
									{locale.submit}
								</Button>
							</div>
						</Form.Item>

					</Form>
				</div>
			</Card>
		</div>
	);
};

OutletLocationFormBody.defaultProps = {
	isEdit: false,
	itemRecord: {},
};

OutletLocationFormBody.propTypes = {
	isEdit: PropTypes.bool,
	itemRecord: PropTypes.object,
};

export default OutletLocationFormBody;