import axios from 'utils/axios';
import {URLS} from 'utils/constants';
import crashLogger from 'utils/logger';
const baseURL = URLS.ORDERHUB_BASE_URL;

export const userLogin = async ({username, password}) => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/auth/login`,
			data: {
				username,
				password,
			},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const userLogout = async () => {
	try {
		const {data} = await axios({
			method: 'POST',
			url: `${baseURL}/auth/logout`,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const checkAuth = async () => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/auth/me`,
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getPlatformList = async params => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/cms/platforms`,
			params: {...params, limit: 0},
		});
		data?.data?.rows.sort((a, b) => a.label.localeCompare(b.label));
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getBrandList = async () => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/cms/brand`,
			params: {limit: 999},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};

export const getOutletList = async platformId => {
	try {
		const {data} = await axios({
			method: 'GET',
			url: `${baseURL}/cms/outlet`,
			params: {platformId, isActive: true},
		});
		return data;
	} catch (error) {
		crashLogger.error(error);
		throw error;
	}
};