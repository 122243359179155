import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {Spin} from 'antd';

import LocalPageHeader from 'components/LocalPageHeader';

import Table from './components/Table';
import Filter from './components/Filter';

import {handleErrorFetch, momentDateFormat} from 'utils/utils';
import {getPromoCheck} from 'utils/request/watchtower';
import {getBrandList, getPlatformList} from 'utils/request/global';

const JobDetailPage = () => {
	const navigate = useNavigate();
	const {jobId} = useParams();

	const [jobInfo, setJobInfo] = useState({
		timeCheck: '',
		checkBy: '',
	});
	const [loading, setLoading] = useState(true);
	const [brandList, setBrandList] = useState([]);
	const [platformList, setPlatformList] = useState([]);

	const metricFormRef = useRef();
	const tableFormRef = useRef();

	const backToPromoJob = () => {
		navigate('/watchtower/promo');
	};

	const fetchBrandAndPlatform = async () => {
		try {
			const brandResponse = await getBrandList({limit: 0});
			const platformResponse = await getPlatformList({limit: 0});

			setBrandList(brandResponse.data.rows.sort((a, b) => a.label.localeCompare(b.label)).map(brand => ({
				label: brand.label,
				value: brand.id,
			})));
			setPlatformList(platformResponse.data.rows.sort((a, b) => a.label.localeCompare(b.label)).map(platform => ({
				text: platform.label,
				value: platform.id,
			})));
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	const initialFetch = async () => {
		try {
			setLoading(true);
			const response = await getPromoCheck({
				id: jobId,
			});

			if (response.success && response?.data?.count > 0) {
				const jobResponse = response.data.rows[0];
				setJobInfo({
					timeCheck: momentDateFormat({dateString: jobResponse.createdAt, format: 'DD MMM YYYY - HH:mm'}),
					checkBy: jobResponse.createdBy === null ? 'Robot' : jobResponse?.creator?.username,
				});

				await fetchBrandAndPlatform();
			} else {
				throw {};
			}
		} catch (error) {
			handleErrorFetch(error);
			backToPromoJob();
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		initialFetch();
	}, []);

	return (
		<div className='bg-white h-full overflow-auto'>
			{
				loading
					? (
						<div className='h-full flex justify-center items-center'>
							<Spin size='large' />
						</div>
					)
					: (
						<>
							<LocalPageHeader
								showBackButton
								headerTitle={jobId}
								subtitle={`Time Check: ${jobInfo.timeCheck} by ${jobInfo.checkBy}`}
								backButtonAction={() => backToPromoJob()}
							/>
							<Filter
								ref={metricFormRef}
								tableFormRef={tableFormRef}
								brandList={brandList}
							/>
							<Table
								jobId={jobId}
								ref={tableFormRef}
								metricFormRef={metricFormRef}
								platformList={platformList}
							/>
						</>
					)
			}
		</div>
	);
};

export default JobDetailPage;