import React from 'react';

import dayjs from 'dayjs';

import {CO_LABEL_PAYLOAD} from 'utils/constants';
import {ArrowDownOutlined, CoffeeOutlined} from '@ant-design/icons';
import {Divider} from 'antd';

const PrintPreview = () => {
	const queueNo = 'HA1';
	const orderNumber = 1;

	const plainPayload = localStorage.getItem(CO_LABEL_PAYLOAD);

	const coItem = plainPayload && JSON.parse(plainPayload).CO;
	const barItem = plainPayload && JSON.parse(plainPayload).label.bar;
	const checkerItem = plainPayload && JSON.parse(plainPayload).label.checker;

	const renderLabelSeparator = () => (
		(
			<div className='border p-4 flex flex-col gap-3'>
				{/* Top Section */}
				<div className='flex gap-4 justify-center text-xl'>
					<ArrowDownOutlined />
					HA1
					<ArrowDownOutlined />
				</div>
			</div>
		)
	);

	const renderLabel = (label, sequence, itemCount) => {
		const showBarIcon = label.menuStation == 'bar';

		return (
			<div className='border p-4 flex flex-col gap-3'>
				{/* Top Section */}
				<div className='flex justify-between'>
					<div>{`${orderNumber} - ${queueNo}`}</div>
					<div>{`${sequence}/${itemCount}`}</div>
				</div>
				<div className='flex flex-col gap-1'>
					<div>{label.menuTitle}</div>
					{
						label.childMenus?.map?.((option, index) => (
							<div key={index}>{option}</div>
						))
					}
					{label.notes && <div>* {label.notes}</div>}
					<div className='flex justify-between'>
						<div>{dayjs(new Date()).format('DD-MM-YYYY')}</div>
						{showBarIcon && <CoffeeOutlined />}
					</div>
				</div>
			</div>
		);
	};

	const renderCO = () => {
		return (
			<div className='pb-8'>
				<div className='border w-80 p-6'>

					{/* Top Section */}
					<div>
						<div className='font-bold'>{`${queueNo} - Grab`}</div>
						<div>{'GF-123'}</div>
						<Divider
							dashed
							className='my-3'
						/>
						<div>
							<div>Brand</div>
							<div>Delivery</div>
						</div>
						<div className='mt-3 text-xs flex justify-between'>
							<div>10293020400249200</div>
							<div>{dayjs(new Date()).format('DD-MM-YYYY HH:mm')}</div>
						</div>
					</div>
					<Divider
						dashed
						className='my-3'
					/>
					<div className='flex flex-col gap-3'>
						{
							coItem.map((item, index) => (
								<div
									key={index}
									className='flex gap-6'
								>
									<div>{item.quantity}</div>
									<div className='flex flex-col gap-2'>
										<div>{item.menuLabel}</div>
										{item.options.length ? (
											<div className='flex flex-col gap-1'>
												{item.options.map(option => (
													<div key={option.menuId}>{`${option.menuLabel}`}</div>
												))}
											</div>
										) : null}
										{item.notes && <div>{`*${item.notes}`}</div>}
									</div>
								</div>
							))
						}
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className='bg-white w-full h-full p-10 gap-12 flex flex-col lg:flex-row overflow-auto lg:overflow-hidden'>
			<div className='flex-1 h-full'>
				<div className='font-medium text-xl'>Label</div>
				<div className='flex flex-1 h-full gap-6 pt-6'>

					<div className='flex-1 flex flex-col gap-6'>
						<div>Checker</div>
						<div className='flex-1 flex flex-col gap-6 overflow-auto'>
							{
								checkerItem.length ? renderLabelSeparator() : null
							}
							{
								checkerItem.map((label, index) => <div key={index}>{renderLabel(label, index + 1, checkerItem.length)}</div>)
							}
						</div>
					</div>

					<div className='flex-1 flex flex-col gap-6'>
						<div>Bar</div>
						<div className='flex-1 flex flex-col gap-6 overflow-auto'>
							{
								barItem.length ? renderLabelSeparator() : null
							}
							{
								barItem.map((label, index) => <div key={index}>{renderLabel(label, index + 1, barItem.length)}</div>)
							}
						</div>
					</div>

				</div>
			</div>
			<div className='flex-1 h-full'>
				<div className='font-medium text-xl pb-6'>CO</div>
				<div className='h-full overflow-auto max-w-max'>
					{renderCO()}
				</div>
			</div>
		</div>
	);
};

export default PrintPreview;